@font-face {
    font-family: Space-Grotesk;
    src: url('fonts/SpaceGrotesk-VariableFont_wght.ttf')format('woff');
}

@font-face {
    font-family: Space-Grotesk;
    src: url('fonts/SpaceGrotesk-VariableFont_wght.ttf')format('woff');
}

@font-face {
    font-family: ping-fang;
    src: url('fonts/pingfang.ttf')format('woff');
}

body {
    font-family: Space-Grotesk;
    font-style: normal;
    background-color: #111010;
    background-size: cover;
    overflow: overlay;
    margin: 0;
    padding: 0;
}

main {
    height: 90vh;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    /* background: radial-gradient(100% 100% at 50% -20%, rgb(233 238 242) 0, hsl(206.67deg 25.71% 93.14%) 100%); */
}

.main-content {
    display: flex;
    justify-content: center;
    padding: 16px 0;
}

.vertical-main-container {
    display: grid;
}

.container {
    background: #383c48;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column nowrap;
    padding: 1.5rem;
    border-radius: 8px;
    margin: 0.75rem 1rem;
    border: 1px solid #6c707d;
    color: #fff;
}

.container div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row wrap;
}

select {
    margin: 0;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23242835%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'), linear-gradient(to bottom, transparent 0%, transparent 100%);
    background-repeat: no-repeat, repeat;
    background-position: right 1rem top 0.9rem, 0 0;
    background-size: 0.65em auto, 100%;
    padding: 0.4rem 2rem 0.4rem 1rem;
    scrollbar-width: none;
    -ms-overflow-style: none;
    border: 1px solid #6370e5;
    border-radius: 8px;
    line-height: 1.5;
    color: #6370e5;
    transition: all 200ms ease-in-out;
    font-size: 0.889rem;
}

select.chain-select {
    display: block;
}

.container .network-select {
    flex-flow: column;
    margin-bottom: 0.85rem;
}

.network-select label {
    font-size: 0.8rem;
}

div.account-center-actions {
    flex-direction: column;
}

div.account-center-actions>div {
    margin: 8px;
}

.bn-demo-button {
    background: #ffffffee;
    font-size: 0.889rem;
    border: 1px solid #eeeeee;
    border-radius: 8px;
    padding: 0.55rem 1.4rem;
    color: #6370e5;
    margin: 0.4rem 0.75rem;
    transition: background 200ms ease-in-out, color 200ms ease-in-out;
}

.bn-demo-button:focus {
    outline: none;
}

.bn-demo-button:hover {
    cursor: pointer;
}

.selected-toggle-btn {
    background: #6370e5;
    color: white;
}

.unselected-toggle-btn {
    color: #6370e5;
    background: #ffffffee;
}

.ui-settings {
    text-align: center;
}

.ui-settings .conditional-ui-settings {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.bn-gas-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.bn-gas {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0 0.5rem;
}

.gas-container {
    background: #383c48;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column nowrap;
    padding: 1rem;
    border-radius: 8px;
    margin: 0.5rem;
    border: 1px solid #6c707d;
    color: #fff;
}

.rpc-gas-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.rpc-gas {
    width: fit-content;
}

.powered-by-container {
    display: none!important;
}

.active{
    background-color: #9aeae1;
    border: 1px solid transparent;
    border-radius: 30px;
    padding: 7px 12px 7px 12px;
}

#dash-nav{
    margin: 15px;
    text-decoration-line: none;
    padding: 7px 12px 7px 12px;
}

@media screen and (max-width: 800px) {
    body {
        overflow: unset;
        overflow-x: hidden;
    }

    section {
        flex-direction: column;
        align-items: center;
    }
    div.container {
        width: unset;
    }
}

@media screen and (max-width: 770px) {
    .main-content {
        flex-direction: column;
        align-items: center;
    }
    div.container {
        padding: 1rem;
        width: calc(100vw - 4rem);
        text-align: center;
    }
}

@media screen and (max-width: 450px) {
    .container .send-transaction-container {
        flex-direction: column;
        margin: 4px auto;
    }
}